import React from "react";
import CellsSection from "../../../Common/CommonSections/CellsSection";
import { 
    StyledSectionTitle, 
    StyledSectionDescription, 
} from "../../common-styles";
import { 
    EXPERIENCE_CELLS_SECTION_TITLE,
    EXPERIENCE_CELLS_SECTION_DESCRIPTION,
    EXPERIENCE_CELLS 
} from "../../constants";

const ExperienceCells = () => {
    return (
        <>
        <StyledSectionTitle>{EXPERIENCE_CELLS_SECTION_TITLE}</StyledSectionTitle>
        <StyledSectionDescription style={{ marginBottom: "45px" }}>
            {EXPERIENCE_CELLS_SECTION_DESCRIPTION}
        </StyledSectionDescription>
        <CellsSection configuration={EXPERIENCE_CELLS} />
        </>
    )
}

export default ExperienceCells;